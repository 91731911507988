import React from 'react';
import logo from '../images/logo.png';
import AddCommentIcon from '@material-ui/icons/AddComment';
import { PortalState, UserEntity } from '../reducers/states';
import { connect } from 'react-redux';


interface FooterProps {
}


class Footer extends React.Component<FooterProps> {

  render() {
    const improveClaraLink = `https://digital-ant.typeform.com/to/Bd4x6W?clara=expo`;

    return (
      <div className='footer container mb-10 mt-40 px-6 flex justify-between items-end flex-wrap'>
        <div className='flex flex-col sm:my-1 md:my-2 my-5'>
          <a href='mailto:martin.safarik@redbuttonedu.cz' className='mail'>
          martin.safarik@redbuttonedu.cz
          </a>
          <a href='tel:+420724149039' className=''>
            +420 724 149 039
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: PortalState): FooterProps => {
  return {
    
  };
};

export default connect(mapStateToProps)(Footer);
