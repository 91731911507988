import React, { ChangeEventHandler, ChangeEvent } from 'react';
import './header.css';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import Feedback from './feedback';
import Filters from './filters';
import { Link } from 'react-router-dom';
import { PortalState, FilterState, UserEntity } from '../reducers/states';
import logoIcon from '../images/clara.png';
import logoIcon2 from '../images/expo.png';
import { logOut, refreshTips } from '../actions/index';
import { Enumerators } from '../entities/local/enumerator';

interface HeaderStateProps {
  isOpen: boolean;
  menuOverlay: boolean;
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

interface HeaderProps {
  filters: FilterState;
  enumerators: Enumerators;
}
interface HeaderDispatchProps {
  logOut(redirect: boolean): any;
  refreshTips(): any;
}

export class Header extends React.Component<HeaderProps & HeaderDispatchProps, HeaderStateProps> {

  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: false,
      menuOverlay: false,
    };
  }

  logout = () => {
    this.props.logOut(true);
  }

  openModal = () => {
    this.setIsOpen(true);
  }

  closeModal = () => {
    this.setIsOpen(false);
  }

  setIsOpen(isOpen: boolean) {
    this.setState(
      {
        ...this.state,
        isOpen: isOpen,
      }
    );
  }

  refreshTips() {
    this.props.refreshTips();
  }

  switchOverlay(menuOverlay: boolean) {
    this.setState(
      {
        ...this.state,
        menuOverlay: menuOverlay,
      }
    );
  }

  render() {
    return (
      <div>
        <nav className='mt-20 container'>
          <div className='flex justify-between'>
            <div className='lg:flex-row flex-col lg:flex items-center'>
              <Link to={{pathname: `/`}} className='flex' onClick={() => { this.refreshTips(); } }>
                <div className='flex-shrink'>
                  <img className='logoicon' src={logoIcon2}></img>
                </div>
              </Link>
            </div>
            <div className='mt-6 lg:flex-row flex-col hidden lg:flex'>
              <Link to={{pathname: `/`}} className='mx-2 text-lg' onClick={() => { this.refreshTips(); } }>
                Happy EXPO 2024
              </Link>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (state: PortalState): HeaderProps => {
  return {
    filters: state.filters,
    enumerators: state.airtable.enums,
  };
};

const dispatchProps = {
  logOut,
  refreshTips,
};

export default connect(mapStateToProps, dispatchProps)(Header);
