import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import TipsList from './components/tips';
import TipDetail from './components/tipdetail';
import { store } from './store';
import { push } from 'react-router-redux';
import { PortalState, FilterState, UserEntity } from './reducers/states';
import { loadApp, redirect, fetchEnums, setLoggedInUser } from './actions/index';
import { getFilterFromQueryString } from './utils';
import { TipTypes, GenericPageType } from './entities/local/enumerator';
import GenericPage from './components/generic-page';

interface AppStateProps {
  appLoaded: boolean;
  appName: string;
  redirectTo: string;
  isLoggedIn: boolean;
}

interface AppDispatchProps {
  redirect(): any;
  loadApp(isLoggedIn: boolean, filters?: FilterState): any;
  fetchEnums(): any;
  setLoggedInUser(user: UserEntity): any;
}

class App extends React.Component<AppStateProps & AppDispatchProps> {
  componentWillReceiveProps(nextProps: AppStateProps & AppDispatchProps) {
    if (nextProps.redirectTo) {
      store.dispatch(push(nextProps.redirectTo));
      this.props.redirect();
    } else {
      if((nextProps as any).history && (nextProps as any).history.action === "POP" && (nextProps as any).location && (nextProps as any).location.search) {
        const filtersObj = getFilterFromQueryString((nextProps as any).location.search as string);
        this.props.loadApp(true, filtersObj);
      }
    }
  }

  componentWillMount() {
    const loggedUser = localStorage.getItem('user');
    let filtersObj: FilterState | undefined;
    if((this.props as any).location && (this.props as any).location.search) {
      filtersObj = getFilterFromQueryString((this.props as any).location.search as string);
    }

    if (!loggedUser) {
      this.props.loadApp(false, filtersObj);
    } else {
      const loggedUserEntity = JSON.parse(loggedUser) as UserEntity;
      this.props.fetchEnums();
      this.props.loadApp(true, filtersObj);
      this.props.setLoggedInUser(loggedUserEntity);
    }
  }

  render() {
    if (this.props.appLoaded) {
      const displayHF = ((this.props as any).location.pathname as string).toLowerCase() !== '/change_password';
      return (
        <div>
          { this.props.isLoggedIn && displayHF && <Header /> }
            <Switch>
              <Route path='/index.html' exact render={() => <TipsList type={TipTypes.TOOLS} />} />
              <Route path='/' exact render={() => <TipsList type={TipTypes.TOOLS} />} />
              <Route path='/clara-ukazka' exact render={() => <TipsList type={TipTypes.PRACTICAL_TIPS} />} />
              <Route path='/expo' exact render={() => <TipsList type={TipTypes.TOOLS} />} />
              <Route path='/clara-ukazka/:id' render={(props: any) => <TipDetail {...props} type={TipTypes.PRACTICAL_TIPS} />} />
              <Route path='/expo/:id' render={(props: any) => <TipDetail {...props} type={TipTypes.TOOLS} />} />
              <Route path='/how_to_begin' exact render={() => <GenericPage type={GenericPageType.HOW_TO_BEGIN} />} />
              <Route path='/what_are_principles' exact render={() => <GenericPage type={GenericPageType.WHAT_ARE_PRINCIPLES} />} />
            </Switch>
          { this.props.isLoggedIn && displayHF && <Footer /> }
        </div>
      );
    }
    return (
      <div>
        <Header />
      </div>
    );
  }
}

const mapStateToProps = (state: PortalState) => {
  return {
    appLoaded: state.common.appLoaded,
    appName: state.common.appName,
    redirectTo: state.common.redirectTo,
    isLoggedIn: true,
  };
};

const dispatchProps = {
  loadApp,
  redirect,
  fetchEnums,
  setLoggedInUser
};



export default connect(mapStateToProps, dispatchProps)(App);
