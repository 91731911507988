import {
  APP_LOAD,
  REDIRECT,
  LOGOUT,
  LOGIN,
  LOGIN_BEGIN,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_BEGIN,
  CHANGE_LOGGED_IN_USER
} from '../actions/types';
import { CommonState, UserEntity } from './states';
import { LoadAppAction, LogoutAction } from '../actions/types';
import { LoginResponse } from '../entities/api/login-response';

const defaultState: CommonState = {
  appName: 'Portal',
  appLoaded: false,
  redirectTo: null,
};

export default (state: CommonState = defaultState, action: any): CommonState => {
  switch (action.type) {
    case APP_LOAD:
      return {
        ...state,
        appLoaded: true,
        redirectTo: null,
      };
    case REDIRECT:
      return { ...state, redirectTo: null };
    default:
      return state;
  }
};
